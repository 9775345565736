<template>
  <div class="qr-container">
    <img v-if="qrLink" :src="qrLink" class="download-img" style="width: 500px; heigth: 500px;" alt="qr_code_url">
    <p v-else>{{ $t('basics.qr_code_error') }}</p>
  </div>
</template>
<script>
export default {
  name: 'QRcodePage',
  data() {
      return {
        qrLink: ''
      }
  },
  mounted() {
    this.qrLink = this.$route.query.qrLink;
  }
}
</script>
<style scoped>
  .qr-container {
    height: 100vh; 
    display: flex; 
    justify-content: center; 
    align-items: center;
  }
</style>